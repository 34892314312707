import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Apple UIKit?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The Apple UIKit is a framework that offers the core objects needed to build apps for tvOS and iOS."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Is Apple UIKit Beneficial To My Business?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "If you want to build apps for iOS and tvOS to display your content and increase your ROI, you need Codestaff Apple UIKit professionals. Codestaff offers you the best professionals in the field and our professionals are always ready to help you."
        }
    }
}

const AppleUikit = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> APPLE UIKit Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top APPLE UIKit Developers. Top
                                    companies and start-ups choose Codestaff Apple UIKit experts
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top APPLE UIKit Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE APPLE UIKIT DEVELOPERS' banner='/apple-uikit.png' bannerAlt='apple uikit banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default AppleUikit