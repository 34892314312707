import React from 'react'
import Layout from '../components/layout'
import AppleUikit from '../components/categories/apple-uikit'
import Seo from '../components/seo'

const AppleUikitPage = ({ location }) => {
  return (
    <Layout location={location}>
      <AppleUikit />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Apple UIKit Freelancers | Codestaff'
  const description =
    'Hire the best Apple UIKit freelancers at Codestaff. Get the top 1% of Apple UIKit professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default AppleUikitPage
